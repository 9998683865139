<template>
  <div class="wrapper">
    <el-container>
      <el-header height="50px">
        <heads />
      </el-header>
      <el-container>
        <el-aside>
          <sideBar />
        </el-aside>
        <el-main>
          <div class="contentBox">
            <v-tags></v-tags>
            <div class="content">
              <transition name="fade-transform" mode="out-in">
                <keep-alive :include="tagsList">
                  <router-view class="loading-area"></router-view>
                </keep-alive>
              </transition>

              <el-backtop target=".content"></el-backtop>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import heads from "./components/header/Header";
import sideBar from "./components/sideBar/SideBar";
import vTags from "./components/tabs/Tags";
export default {
  components: {
    heads,
    sideBar,
    vTags,
  },
  props: {},
  data() {
    return {
      tagsList: [],
      transitionName: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    this.$bus.$on("tags", (msg) => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  .fadeIn-enter-active {
    animation-name: fadeIn;
    animation-duration: 2s;
  }
  .fadeIn-leave-active {
    animation-name: fadeOut;
    animation-duration: 0.5s;
  }
  .el-container {
    height: 100%;
    width: 100%;
    .el-header {
      padding: 0;
    }
    .el-aside {
      overflow: hidden;
      width: auto !important;
    }
    .el-main {
      padding: 0;
      position: relative;
      overflow: hidden !important;
    }

    .el-main {
      width: 100%;
      background-color: #f0f0f0;
      .contentBox {
        position: absolute;
        left: 0px;
        right: 0;
        top: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #f0f0f0;
        overflow: hidden;
        -webkit-transition: left 0.3s ease-in-out;
        transition: left 0.3s ease-in-out;
        box-sizing: border-box;
        .content {
          margin-top: 30px;
          height: calc(100% - 20px);
        }
      }
    }
  }
}
</style>