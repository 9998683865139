<template>
  <div class="wrapper">
    <el-menu
      :router="true"
      class="sidebar-el-menu"
      background-color="#324157"
      text-color="#fff"
      active-text-color="#20a0ff"
      :unique-opened="true"
      :collapse="isCollapse"
      :default-active="currentRouter"
    >
      <template v-for="item in routerPath">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template slot="title">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template slot="title">{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import role from "@/util/role";
export default {
  name: "SideBar",
  components: {},
  props: {},
  data() {
    return {
      //折叠
      isCollapse: false,
      routerPath: [],
    };
  },
  watch: {},
  computed: {
    currentRouter() {
      return "/" + this.$route.path.split("/").reverse()[0];
    },
  },
  methods: {},
  created() {
    //折叠
    this.$bus.$on("collapse", (data) => {
      this.isCollapse = data;
    });
    this.handleRoleSideBar();
  },
  mounted() {},
  methods: {
    //根据角色设置侧边栏
    handleRoleSideBar() {
      const curRole = this.$cookies.get("curRole");
      this.routerPath = role[curRole];
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  .el-menu {
    height: 100%;
    overflow-y: hidden;
  }
  /*webkit内核*/
  .el-menu::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  /*o内核*/
  .el-menu .-o-scrollbar {
    -moz-appearance: none !important;
    background: rgba(0, 255, 0, 0) !important;
  }
  /*IE10,IE11,IE12*/
  .el-menu {
    -ms-scroll-chaining: chained;
    -ms-overflow-style: none;
    -ms-content-zooming: zoom;
    -ms-scroll-rails: none;
    -ms-content-zoom-limit-min: 100%;
    -ms-content-zoom-limit-max: 500%;
    -ms-scroll-snap-type: proximity;
    -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
    -ms-overflow-style: none;
    overflow: auto;
  }
  /deep/.sidebar-el-menu:not(.el-menu--collapse) {
    width: 200px;
  }
  i {
    color: #fff;
  }
}
</style>
